import { ClassHitDie } from "./Rules";
import Player from "./Player";
import { playerStore } from "./player-store";
import combatStore from "../components/combat/combat-store";
import { Configuration, OpenAIApi } from "openai";

export function outputToChat(message) {
  const chatHistory = document.getElementById("chat-history");
  const apiMessageElement = document.createElement("p");
  apiMessageElement.classList.add("api-message");
  if (combatStore.inCombat) {
    //We should be in combat, but worth checking anyway.
    apiMessageElement.classList.add("combat-message");
  }
  //TO-DO: Checks here about appropriate response (if we had layers of ai)
  if (!message.startsWith("DungeonFysh: ")) {
    message = "DungeonFysh: " + message;
  }
  apiMessageElement.textContent = message;

  chatHistory.appendChild(apiMessageElement);

  // Scroll chat history to the bottom
  chatHistory.scrollTop = chatHistory.scrollHeight;
}

//Takes an ability score and returns it's modifier value (e.g 0 for 10, 1 for 12 etc.)
export function calculateAbilityScoreModifier(abilityscore) {
  return Math.floor((abilityscore - 10) / 2);
}

export function calculateAverageMaxHP(characterClass, conMod, level) {
  const hitDie = getCharacterClassHitDie(characterClass);
  return hitDie + ((level - 1) * Math.floor(hitDie) + 1) + conMod * level;
}

export function getCharacterClassHitDie(characterClass) {
  return ClassHitDie[characterClass];
}

//Semi redundant for now, but will be built upon later when giving choice in character creation
//For now most values are set as defaults within Player.js constructor as well as placing player
//into player-store
export function createNewPlayer(name) {
  return new Player(name);
}

//Temp testing function for creating test player. Not efficent.
//Creates a player1 if we don't have one
export function createPlayer1() {
  if (!playerStore.players[0]) {
    createNewPlayer("Champ");
  }
}

//waits for the ms passed as an argument
export async function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}
export function removeItemAll(arr, value) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

//Function to remove white space from start of string
export function trimStart(str) {
  if (!str) return str;
  return str.replace(/^\s+/g, "");
}

const configuration = new Configuration({
  organization: "org-sMMER6pR7vDwsEzJa9rwc2Cv",
  apiKey: process.env.REACT_APP_OPENAPIKEY,
});
const openai = new OpenAIApi(configuration);

//Function for getting responses from openAI
export async function getOpenApiAiResponse(prompt, temp, maxTokens, model) {
  try {
    const response = await openai.createCompletion({
      model: model,
      prompt: prompt,
      temperature: temp,
      max_tokens: maxTokens,
      stop: ["###"],
    });
    if (response) {
      return trimStart(response.data.choices[0].text);
    }
  } catch (error) {
    throw error;
  }
}

//Takes an array and a second arg. Removes second arg from the array and returns it.
export function removeItemFromArray(array, item) {
  return array.filter((newArray) => {
    return newArray !== item;
  });
}
