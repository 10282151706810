import React, { Component } from "react";
import CombatGrid from "./combat-grid";
import "./combatStyle.css";
import { playerStore } from "../../data/player-store";
import Enemy from "../../data/Enemy";
import {
  createPlayer1,
  getOpenApiAiResponse,
  wait,
} from "../../data/GlobalFunctions";
import combatStore, {
  setInCombat,
  updateGridSquare,
  addEnemies,
  sortTurnOrderDescending,
  resetCombatGrid,
  moveActor,
  calculateDistance,
  clearTurnOrder,
  setCombatInstance,
  addTurn,
  validateMovement,
  setCombatInfoReady,
  setLoading,
  fleeCombat,
} from "./combat-store";
import { action } from "mobx";
import { GridTypes } from "../../data/GridTypes";
import resolveAttackStore from "./resolve-attack-window-store";
import TurnOrder from "./turn-order";
import { outputToChat } from "../../data/GlobalFunctions";
import { observer } from "mobx-react";
import { proficientRoll } from "../../data/Rolls";
import CombatInfoWindow from "./combat-info-window";

//TO-DO - we could split this component and combat out seperatley but would take some thought
//It is a bit of a jumble atm from where I was figuring out how react and classes interacted
class Combat extends Component {
  state = {
    gridSize: this.props.gridSize,
  };

  enemyTurn = false;

  //Lets create a new player here if we don't already have one (check inside function) (not efficent).
  //Later this should be done outside of combat code
  //i.e during character creation or loading of the site/page/app.
  player = createPlayer1();

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutsideActorMenu);
    this.setState({
      newCombatGrid: (
        <CombatGrid gridSize={this.state.gridSize} classname="combat-grid" />
      ),
    });
    setCombatInstance(this);
    this.combatStart();
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutsideActorMenu);
  }

  //Handle the start of combat
  async combatStart() {
    //Clear any past combat variables
    setInCombat(true);
    outputToChat("--- Combat Start ---");
    clearTurnOrder();
    combatStore.participants = [];
    resetCombatGrid();

    const player1 = playerStore.players[0];
    //Detrmine combatants and establish particpants store
    //For now just 1 enemy and 1 player
    combatStore.participants.push(player1);

    //Create the enemy/enmies and add them to participants and enemies in combat-store
    setLoading(true);

    let enemies = await this.generateEnemies();
    addEnemies(enemies);
    //--No surprise rounds yet-- it would be determined here

    //--Establish Positions-- (More work needed for multiple players, e.g based on marching order etc)
    this.placeAgent(player1);
    this.placeEnemies(enemies);

    //--Roll initiative--
    //First roll the inativies

    //Player
    let player1Init = player1.rollInitiative();
    let playerTurnObject = { initiative: player1Init, actor: player1 };
    addTurn(playerTurnObject);

    //Enemies
    enemies.forEach((enemy) => {
      let enemyInit = enemy.rollInitiative();
      let enemyTurnObject = { initiative: enemyInit, actor: enemy };
      addTurn(enemyTurnObject);
    });

    //Then determine turn order
    sortTurnOrderDescending();

    //Update menus
    setCombatInfoReady(true);
    setLoading(false);

    //--Start turn order--
    this.startNextTurn();
  }

  //Simple "AI" for enemy turns currently.
  async startEnemyTurn(enemyCurrentTurn) {
    this.isEnemyTurn = true;

    outputToChat(enemyCurrentTurn.name + " is thinking... It is their turn");
    await wait(1000);
    //Get nearest Player
    let nearestPlayer = this.getNearestPlayer(enemyCurrentTurn.location);

    //Move towards player if not within 5ft
    while (
      enemyCurrentTurn.remainingMovement > 0 &&
      nearestPlayer.distance > 5
    ) {
      let targetLocation = this.getNextEnemyLocation(
        enemyCurrentTurn,
        nearestPlayer.player
      );

      //If there is no valid location, stop trying to move.
      if (targetLocation === false) {
        break;
      }

      moveActor(targetLocation, enemyCurrentTurn);

      nearestPlayer.distance = calculateDistance(
        enemyCurrentTurn.location,
        nearestPlayer.player.location
      );
      await wait(1000);
    }

    //Handle attacks.
    let attack = null;
    while (enemyCurrentTurn.remainingAttacks > 0) {
      //Use melee attack if in melee range. TO-DO: implement reach
      if (nearestPlayer.distance <= 5) {
        attack = enemyCurrentTurn.attacks.meleeAttack;
      }
      //Or we are not in melee so used ranged if we have it
      else if (enemyCurrentTurn.attacks.rangedAttack !== null) {
        attack = enemyCurrentTurn.attacks.rangedAttack;
      }
      //Otherwise we have no valid attacks so we are done
      else {
        break;
      }
      await this.resolveEnemyAttack(enemyCurrentTurn, attack, nearestPlayer);
    }

    await wait(2000);
    outputToChat(enemyCurrentTurn.name + " has finished their turn.");
    this.isEnemyTurn = false;
    this.endCurrentTurn();
  }

  getNextEnemyLocation(enemy, player) {
    // Calculate the direction from the enemy to the player.
    const direction = [
      player.location[0] - enemy.location[0],
      player.location[1] - enemy.location[1],
    ];

    // Calculate the length of the direction vector.
    const length = Math.sqrt(direction[0] ** 2 + direction[1] ** 2);

    // Normalize the direction vector to get a unit vector in the same direction.
    const unitDirection = [direction[0] / length, direction[1] / length];

    // Calculate the next location for the enemy to move towards the player by
    //adding the unit direction vector to the enemy's current location.
    let nextLocation = [
      Math.round(enemy.location[0] + unitDirection[0]),
      Math.round(enemy.location[1] + unitDirection[1]),
    ];

    // Check if the next location is valid.
    if (validateMovement(nextLocation)) {
      return nextLocation;
    }

    // If the next location is not empty, find the next available empty grid square
    // within 5 feet of the enemy's current location that is closest to the player.
    const adjacentLocations = [
      [enemy.location[0] + 1, enemy.location[1]],
      [enemy.location[0] + 1, enemy.location[1] + 1],
      [enemy.location[0], enemy.location[1] + 1],
      [enemy.location[0] - 1, enemy.location[1] + 1],
      [enemy.location[0] - 1, enemy.location[1]],
      [enemy.location[0] - 1, enemy.location[1] - 1],
      [enemy.location[0], enemy.location[1] - 1],
      [enemy.location[0] + 1, enemy.location[1] - 1],
    ];

    let closestLocation = null;
    let closestDistance = Infinity;

    for (let i = 0; i < adjacentLocations.length; i++) {
      const adjacentLocation = adjacentLocations[i];
      if (validateMovement(adjacentLocation)) {
        const distance = calculateDistance(adjacentLocation, player.location);
        if (distance < closestDistance) {
          closestLocation = adjacentLocation;
          closestDistance = distance;
        }
      }
    }
    return closestLocation ?? false;
  }

  async resolveEnemyAttack(enemyCurrentTurn, thisAttack, nearestPlayer) {
    let attackRoll = enemyCurrentTurn.useAttack(() =>
      proficientRoll(thisAttack.bonusToHit)
    );
    let crit = attackRoll[1] === 20 ? true : false;
    outputToChat(
      enemyCurrentTurn.name + " attaks with their " + thisAttack.name + "."
    );
    await wait(2000);
    if (attackRoll[0] >= nearestPlayer.player.AC || crit) {
      outputToChat(
        enemyCurrentTurn.name +
          " rolled " +
          attackRoll[0] +
          " total" +
          (crit ? " and CRIT!" : " which hits!") +
          " Rolling for damage"
      );

      await wait(2000);
      //Roll for damage and resolve
      const minDamage = thisAttack.damageRange[0];
      const maxDamage = thisAttack.damageRange[1];
      const damageRoll =
        Math.floor(Math.random() * (maxDamage - minDamage + 1)) + minDamage;
      let damage = crit ? damageRoll * 2 : damageRoll;
      nearestPlayer.player.reduceHP(damage);
      outputToChat(
        enemyCurrentTurn.name + " hits you for " + damage + " damage!"
      );
    } else {
      //The attack missed
      outputToChat(
        enemyCurrentTurn.name + " rolled " + attackRoll[0] + ", which misses!"
      );
    }

    //If we have no attacks left, end turn
    if (enemyCurrentTurn.remainingAttacks < 1) {
      //return true so we know to break the while loop
      return true;
    }
  }

  placeAgent = (Agent) => {
    //Add Agent to grid based on their location data
    updateGridSquare(Agent.location[0], Agent.location[1], Agent);
  };

  //Handle multiple enemies and ensure good locations
  placeEnemies = action((enemies) => {
    let locX = 0;
    let locY = 0;
    enemies.forEach((enemy) => {
      enemy.setLocation(locX, locY);
      this.placeAgent(enemy);
      locX++;
    });
  });

  //Called in 'combat-grid' when a grid-square is clicked on
  gridSquareClicked = (location, squareRef) => {
    //Get the actor who's turn it is now
    let actorCurrentTurn = combatStore.turnOrder[0].actor;

    //If its a players turn
    if (actorCurrentTurn.type === GridTypes.PlayerType) {
      //Did player click on an enemy?
      if (
        combatStore.combatGrid[location[0]][location[1]].type ===
        GridTypes.EnemyType
      ) {
        let enemyTarget = combatStore.combatGrid[location[0]][location[1]];
        resolveAttackStore.enemyTarget = enemyTarget;
        combatStore.distanceToTarget = calculateDistance(
          actorCurrentTurn.location,
          enemyTarget.location
        );
        //Set Pos of actorClickMenu
        this.props.updateActorClickedMenuPosition({
          top: squareRef.offsetTop,
          left: squareRef.offsetLeft,
        });
        //Populate Menu
        combatStore.actorDropDown = actorCurrentTurn.getAllActionsOther();
        //Show ActorClickedMenu
        this.props.setActorClickedMenuVisible(true);

        //Did player click on self
      } else if (
        combatStore.combatGrid[location[0]][location[1]] === actorCurrentTurn
      ) {
        //Set Pos of actorClickMenu
        this.props.updateActorClickedMenuPosition({
          top: squareRef.offsetTop,
          left: squareRef.offsetLeft,
        });
        //Populate Menu
        let selfActions = actorCurrentTurn.getAllActionsSelf();
        combatStore.actorDropDown =
          selfActions.length > 0
            ? selfActions
            : [{ name: "No suitable actions", subItems: "" }];

        //TO-DO: See below comment for adding bonus actions to the list
        // combatStore.actorDropDown = combatStore.actorDropDown.concat(
        //   actorCurrentTurn.getBonusActionsSelf()
        // );
        //Show ActorClickedMenu
        this.props.setActorClickedMenuVisible(true);
      }

      //Try to move player(checks done inside moveActor)
      moveActor(location, actorCurrentTurn);
    }
  };

  handleClickOutsideActorMenu = (event) => {
    const { isActorClickedMenuVisible, setActorClickedMenuVisible } =
      this.props;
    if (
      event.target.id !== "enemy-grid" &&
      event.target.id !== "player-grid" &&
      !event.target.closest("#enemy-clicked-menu") &&
      isActorClickedMenuVisible
    ) {
      setActorClickedMenuVisible(false);
    }
  };

  startNextTurn = action(() => {
    //Start next turn
    if (combatStore.turnOrder[0].actor.type === GridTypes.PlayerType) {
      outputToChat(combatStore.turnOrder[0].actor.name + ", it is your turn!");
    } else {
      this.startEnemyTurn(combatStore.turnOrder[0].actor);
    }
  });

  //End current turn and update turn order
  endCurrentTurn = action(() => {
    let turnObject = combatStore.turnOrder.shift();
    turnObject.actor.endOfTurn();
    combatStore.turnOrder.push(turnObject);
    this.startNextTurn();
  });

  //Get nearest player to given location. Returns a single player and distance as an array.
  getNearestPlayer = action((location) => {
    let nearestPlayer = null;
    let nearestDistance = Infinity;
    let players = combatStore.participants.filter(
      (p) => p.type === GridTypes.PlayerType
    );

    for (const player of players) {
      const distance = calculateDistance(location, player.location);
      if (distance < nearestDistance) {
        nearestPlayer = player;
        nearestDistance = distance;
      }
    }

    return { player: nearestPlayer, distance: nearestDistance };
  });

  getLast10ChatLines() {
    let last10Chat = "";
    let elements = Array.from(
      document.querySelectorAll("#chat-history > p")
    ).slice(-10);

    if (elements.length > 10) {
      //Greater than 10 to ignore intro
      elements.forEach((chatEntry) => {
        chatEntry.querySelector(".feedback-button")?.remove();
        last10Chat += " " + chatEntry;
      });
    } else {
      //index from 1 to ignore intro
      for (let i = 1; i < elements.length - 1; i++) {
        elements[i].querySelector(".feedback-button")?.remove();
        last10Chat += elements[i].innerHTML;
      }
    }
    return last10Chat;
  }

  async generateEnemies() {
    //Generate prompt for getting enemies
    let last10Chat = this.getLast10ChatLines();
    let template = {
      name: "-",
      AC: "-",
      maxHP: "-",
      abilityScores: {
        str: "-",
        dex: "-",
        con: "-",
        int: "-",
        wis: "-",
        cha: "-",
      },
      speed: "-",
      size: "-",
      attacksPerTurn: "-",
      meleeAttack: { name: "-", bonusToHit: "-", damageRange: ["-", "-"] },
      rangedAttack: { name: "-", bonusToHit: "-", damageRange: "-" },
    };

    let enemyPrompt =
      "Generate JSON stats for enemies in a D&D5e game." +
      "Here is the template for stats, a dash indicates  a stat requiring completion:" +
      "\n---\n" +
      JSON.stringify(template) +
      "\n---\n" +
      "Always provide a suitable meleeAttack." +
      "Only provide a ranged attack if relevant, otherwise rangedAttack can be null eg:" +
      " `rangedAttack: null`. For melee and ranged, `bonusToHit` should be an integer," +
      " `damageRange` should be a min and max, eg: `damageRange: [2,8]`\n" +
      "You should respond only with the template." +
      "Respond with an array of results if there is more than 1 enemy eg " +
      "[{first_enemy_stats}, {second_enemy_stats}]\n" +
      "When creating stats for the enemies, ensure combat is manageable by the following: " +
      "There is " +
      playerStore.players.length +
      " player(s), player level is: " +
      playerStore.players[0].level +
      ".\n The text that follows is from the game for which you are to generate enemies." +
      "Check the text for the type and count of enemies based " +
      "on what DungeonFysh(the DM) has said" +
      "\n---\n" +
      last10Chat +
      "\n---\n" +
      "IMPORTANT: ONLY RESPOND WITH THE JSON DATA, NO ADDTIONAL TEXT OF ANY KIND";

    let response = await getOpenApiAiResponse(
      enemyPrompt,
      0.1,
      1000,
      "text-davinci-003"
    );

    //Its rare, but sometimes the response is not valid json.
    //So we try twice incase of this.
    let enemiesObject = null;
    try {
      enemiesObject = JSON.parse(response);
    } catch (e) {
      try {
        enemiesObject = JSON.parse(response);
      } catch (e) {
        alert(
          "There was an error generating enemies\nYou should reload the page."
        );
        throw e;
      }
    }

    return this.processResponseToTemplate(enemiesObject);
  }

  processResponseToTemplate(response) {
    let enemies = [];
    response.forEach((enemyTemplate) => {
      const newEnemy = new Enemy(
        enemyTemplate.name,
        enemyTemplate.AC,
        enemyTemplate.maxHP,
        enemyTemplate.abilityScores,
        enemyTemplate.speed,
        enemyTemplate.size,
        enemyTemplate.meleeAttack,
        enemyTemplate.rangedAttack ?? null,
        enemyTemplate.attacksPerTurn
      );

      enemies.push(newEnemy);
    });

    return enemies;
  }

  //Style for loading die

  render() {
    return (
      <div>
        {this.state.newCombatGrid}
        <div id="combat-info-container">
          <TurnOrder />
          <button
            onClick={() => this.endCurrentTurn()}
            id="end-turn-button"
            disabled={
              combatStore.turnOrder.length === 0 ||
              combatStore.turnOrder[0].actor.type !== GridTypes.PlayerType ||
              //Don't end turn with resolve-attack-window open, causes bugs
              resolveAttackStore.isResolveAttackVisible
            }
          >
            End Turn
          </button>
          <button
            onClick={() => {
              if (
                window.confirm("Are you sure you want to flee from combat?")
              ) {
                fleeCombat();
              }
            }}
            id="flee-button"
            disabled={
              combatStore.turnOrder.length === 0 ||
              combatStore.turnOrder[0].actor.type !== GridTypes.PlayerType ||
              resolveAttackStore.isResolveAttackVisible
            }
          >
            Flee Combat
          </button>
        </div>
        <CombatInfoWindow />
      </div>
    );
  }
}

export default observer(Combat);
